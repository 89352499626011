import { format } from 'date-fns';
import { enUS, ru } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

function useDateFormat() {
  const { i18n } = useTranslation();
  let locale = enUS;

  if (i18n.language === 'ru') {
    locale = ru;
  }

  return (date, formatStr) => format(date, formatStr, { locale });
}

export default useDateFormat;
