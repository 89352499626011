import React from 'react';
import { useTranslation } from 'react-i18next';
import Link from '../../i18n/Link';
import useDateFormat from '../../hooks/useDateFormat';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const PostItem = ({ image, title, category, slug, author, postDate }) => {
  const format = useDateFormat();
  const { i18n } = useTranslation();
  const { language } = i18n;
  const gatsbyImage = image && getImage(image.localFile);
  const imageUrl =
    image?.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src;
  const linkLanguage = language === 'ru' ? 'ru' : 'en';

  return (
    <div className="location-card d-flex flex-column h-100 ">
      <Link language={linkLanguage} to={`/blog/${slug}/`} title={title}>
        {gatsbyImage && (
          <GatsbyImage
            className="card-img rounded-top-10"
            imgClassName="rounded-top-10"
            alt={title}
            image={gatsbyImage}
          />
        )}

        {!gatsbyImage && imageUrl && (
          <div className="card-img rounded-top-10 bg-default-6">
            <img src={imageUrl} alt={title} />
          </div>
        )}

        {!gatsbyImage && !imageUrl && (
          <div className="card-img rounded-top-10 bg-default-6" />
        )}
      </Link>

      <div className="card-content gr-hover-shadow-1 px-9 py-8 bg-white rounded-bottom-10 mb-9 flex-grow-1">
        <p className="gr-text-11 mb-4 d-inline-block gr-color-blackish-blue-opacity-7">
          {category.map(({ id, title, slug }) => {
            return (
              <Link
                language={linkLanguage}
                to={`/${slug}/`}
                className="badge mr-5 text-primary gr-text-15 gr-bg-blue-opacity-1 rounded-pill text-uppercase"
                key={id}
              >
                {title}
              </Link>
            );
          })}
        </p>

        <h2 className="title text-blackish-blue gr-text-7 mb-0">
          <Link language={linkLanguage} to={`/blog/${slug}/`}>
            {title}
          </Link>
        </h2>

        <p className="gr-text-10">
          <Link language={linkLanguage} to={`/author/${author.remoteId}/`}>
            {author.name}
          </Link>
          &nbsp; | &nbsp;
          {format(new Date(postDate), 'dd MMM, yyyy')}
        </p>
      </div>
    </div>
  );
};

export default PostItem;
